/* #6ea7c16
  b55aee67fa2ffefa1
  a8d8eaaa96dafcbad3ffffd2
  3b2e5a3949894ea0aefff48f; */

/* :root {
  --color-light: #fbfbfb;
  --color-dark: #0b0b0b; 
  --color-primary: #0d0d5b;
  --color-primary-light: #0d0d5b99;
  --color-primary-transparent: #0d0d5b00;
  --color-secondary: #eeee2a;
  --color-secondary-light: #eeee2a99;
  --color-tertiary: #3f139a;
  --color-tertiary-light: #3f139a99;
} */
:root {
  --color-light: #0b0b0b;
  --color-dark: #fbfbfb;

  --color-primary: #fbfbfb;
  --color-primary-light: #fbfbfb99;
  --color-primary-transparent: #fbfbfb00;

  --color-secondary: #0d0d5b;
  --color-secondary-light: #6b6b9a;
  --color-secondary-transparent: #0d0d5b00;

  --color-tertiary: #3f139a;
  --color-tertiary-light: #3f139a99;

  --color-highlight: #e6bc10;
  --color-highlight-light: #e6bc1099;

  --color-disabled: #999;
  --color-disabled-dark: #3e3e3e;
  --color-disabled-ligth: #d3d1d1;
  --color-info: white;
  --color-success: white;
  --color-processing: white;
  --color-error: rgb(206, 2, 2);
  /* --color-error-light: rgb(207, 85, 85); */
  --color-error-vlight: #ffb3d2;
  --color-warning: #faad14;
  --color-warning-ligth: #faad1499;
  --color-warning-dark: #a6730e;
}

.theme-light {
  color: var(--color-light) !important;
}
.theme-light-bg {
  background-color: var(--color-light) !important;
}
.theme-dark {
  color: var(--color-dark) !important;
}
.theme-dark-bg {
  background-color: var(--color-dark) !important;
}

/* --------------------------- */

.theme-primary {
  color: var(--color-primary) !important;
}
.theme-primary-bg {
  background-color: var(--color-primary) !important;
}
.theme-primary-border {
  border: 1px solid var(--color-primary) !important;
}

.theme-secondary {
  color: var(--color-secondary) !important;
}
.theme-secondary-bg {
  background-color: var(--color-secondary) !important;
}
.theme-secondary-border {
  border-color: var(--color-secondary) !important;
}
.theme-secondary-bg-light {
  background-color: var(--color-secondary-light) !important;
}

.theme-tertiary {
  color: var(--color-tertiary) !important;
}
.theme-tertiary-bg {
  background-color: var(--color-tertiary) !important;
}
.theme-tertiary-border {
  border: 1px solid var(--color-tertiary) !important;
}

.theme-tertiary-light {
  color: var(--color-tertiary-light) !important;
}
.theme-tertiary-light-bg {
  background-color: var(--color-tertiary-light) !important;
}

/* --------------------------- */
.theme-highlight {
  color: var(--color-highlight) !important;
}
.hover\:theme-highlight :hover {
  color: var(--color-highlight) !important;
}
.theme-highlight-bg {
  background-color: var(--color-highlight) !important;
}
.theme-highlight-border {
  border: 1px solid var(--color-highlight) !important;
}
.theme-highlight-stroke {
  stroke: var(--color-highlight) !important;
}
/* --------------------------- */

.theme-disabled {
  color: var(--color-disabled) !important;
}
.theme-disabled-bg {
  background-color: var(--color-disabled) !important;
}
.theme-disabled-bgs {
  background: var(--color-disabled) !important;
}
.theme-disabled-bg-dark {
  background-color: var(--color-disabled-dark) !important;
}
.theme-disabled-bg-light {
  background-color: var(--color-disabled-ligth) !important;
}
.theme-disabled-border {
  border: 1px solid var(--color-disabled);
}
.theme-disabled-border-light {
  border: 1px solid var(--color-disabled-ligth);
}

.theme-info {
  color: var(--color-info) !important;
}
.theme-info-bg {
  background-color: var(--color-info) !important;
}

.theme-success {
  color: var(--color-success) !important;
}
.theme-success-bg {
  background-color: var(--color-success) !important;
}

.theme-processing {
  color: var(--color-processing) !important;
}
.theme-processing-bg {
  background-color: var(--color-processing) !important;
}

.theme-error {
  color: var(--color-error) !important;
}
.theme-error-vlight {
  color: var(--color-error-vlight) !important;
}
.theme-error-bg {
  background-color: var(--color-error) !important;
}
.theme-error-bg-light {
  background-color: var(--color-error-light) !important;
}
.theme-error-border {
  border: 1px solid var(--color-error) !important;
}
.theme-error-border-vlight {
  border-style: solid;
  border-color: var(--color-error-vlight);
}

.theme-warning {
  color: var(--color-warning) !important;
}
.theme-warning-bg {
  background-color: var(--color-warning) !important;
}
.theme-warning-bg-dark {
  background-color: var(--color-warning-dark) !important;
}

/* --------------------------- */
