@import './theme.css';

/* Components  */

.ant-btn.ant-btn-primary {
  background-color: var(--color-secondary);
  color: var(--color-dark);
  transition: transform 0.3s ease-in-out 0s;
  transition: box-shadow 0.3s ease-in-out 0s;
}
.ant-btn.ant-btn-primary:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
  transform: translateY(-1px);
  box-shadow: 0px 5px 10px var(--color-dark);
  border-color: var(--color-secondary);
}
.ant-btn.ant-btn-primary:disabled {
  transform: none;
  box-shadow: none;
  background-color: var(--color-disabled);
}

.ant-btn.ant-btn-default {
  background-color: var(--color-primary);
  color: var(--color-light);
  transition: all 0.2s ease-in;
}
.ant-btn.ant-btn-default:hover {
  border-color: var(--color-secondary);
  box-shadow: 0px 2px 5px var(--color-secondary);
  color: var(--color-secondary);
}
.ant-btn.ant-btn-default:disabled {
  color: var(--color-light);
  background-color: var(--color-disabled-ligth);
}

.ant-btn.ant-btn-text {
  color: var(--color-secondary);
}
.ant-btn.ant-btn-text:hover {
  color: var(--color-secondary);
  background-color: transparent;
}
.ant-btn.ant-btn-text:disabled {
  background-color: transparent;
}

/* Underlining text button */

.ant-btn.ant-btn-text:hover.ant-btn.ant-btn-text:not(
    .ant-btn[disabled],
    .no-underline
  )::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.ant-btn.ant-btn-text:not(.no-underline)::after {
  content: '';
  position: absolute;
  z-index: inherit;
  width: calc(100% - 32px);
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 16px;
  background: var(--color-secondary);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.ant-btn[disabled] {
  border-color: var(--color-disabled) !important;
  cursor: default;
  box-shadow: none !important;
}

/* ----------------------- */

span.ant-input-group-addon {
  cursor: default;
  color: var(--color-light) !important;
  background-color: var(--color-highlight) !important;
  border: none !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: var(--color-dark);
}

h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5,
h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4,
h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3,
h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2,
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1,
div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}

.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
  background-color: var(--color-disabled-ligth);
}

/* Table */

.ant-table-column-title {
  text-align: center;
}

.ant-empty-description {
  color: var(--color-light) !important;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  color: var(--color-primary);
  background-color: var(--color-secondary) !important;
}
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  color: var(--color-light);
  background-color: var(--color-primary) !important;
}

.ant-table-cell > article > p {
  color: var(--color-light);
}

.ant-table-wrapper .ant-table-thead > tr > th {
  height: 4rem;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0 !important;
  background: transparent !important;
}

/* .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
} */

/* Graph loader */

i.ant-spin-dot-item {
  background-color: var(--color-secondary) !important;
  border-radius: 50%;
  opacity: 1 !important;
}

.ant-spin-text {
  color: var(--color-dark);
}

div.ant-modal.p-0 > div.ant-modal-content {
  padding: 0 !important;
}

.ant-alert-warning {
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

.ant-input-disabled {
  color: var(--color-light) !important;
  border: none;
  background-color: var(--color-disabled-ligth) !important;
  cursor: default !important;
}

/* Evidence upload  */

.anticon-paper-clip {
  color: var(--color-secondary) !important;
}
.ant-upload-list-item-name {
  padding-left: 4px !important;
  color: var(--color-light) !important;
}
.ant-upload-wrapper .ant-upload-list {
  display: flex;
  flex-wrap: wrap;
}

.ant-upload .ant-upload-disabled {
  border-radius: 0.5rem;
  cursor: default !important;
}

.ant-upload-text {
  color: var(--color-light) !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-upload-wrapper
  .ant-upload-drag {
  border-radius: inherit;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--color-ligth) !important;
}
.ant-checkbox:hover > span.ant-checkbox-inner {
  background-color: var(--color-secondary) !important;
}
.ant-checkbox .ant-checkbox-inner::after {
  border-color: var(--color-dark) !important;
}
