@import './theme.css';

/* * {
  border: 1px solid red !important;
} */

h1 {
  font-size: 24px !important;
}
h2 {
  font-size: 22px !important;
}
h3 {
  font-size: 20px !important;
}
h4 {
  font-size: 18px !important;
}

button > span {
  color: inherit;
}

/* Slider  */

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: linear-gradient(
    90deg,
    var(--color-primary),
    var(--color-tertiary)
  );
  cursor: pointer;
  height: 5px;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: var(--color-highlight);
  border: 3px solid hsla(0, 0%, 100%, 0.3);
  border-radius: 2px;
  height: 16px;
  width: 8px;
}

.slider:disabled::-webkit-slider-thumb {
  background: var(--color-disabled);
}

.slider::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background: var(--color-highlight);
  border: 3px solid hsla(0, 0%, 100%, 0.3);
  border-radius: 2px;
  height: 16px;
  width: 8px;
}

.slider:disabled::-moz-range-thumb {
  background: var(--color-disabled);
}

/* Scrollbar  */

* {
  scrollbar-color: var(--color-highlight) var(--color-disabled);
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
::-webkit-scrollbar-track {
  background: var(--color-disabled);
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-highlight);
  border-radius: 2px;
}

/* Components  */

.content-card {
  backdrop-filter: blur(3px);
  border: var(--color-light) 1px solid;
  background: var(--color-primary-light);
  color: var(--color-light);
}
.content-card p,
.content-card h1,
.content-card h2,
.content-card h3,
.content-card h4,
.content-card h5,
.content-card h6 {
  color: var(--color-light);
}

.footer {
  padding: 1rem;
  background-color: var(--color-primary);
}

.logo {
  float: left;
  background-repeat: no-repeat;
  height: 50px;
}

.bg {
  background-color: var(--color-primary);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header-icon {
  background-color: var(--color-tertiary);
}

.header-content {
  display: flex;
  flex-wrap: nowrap;
  flex: 0;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}
.header-content * {
  color: var(--color-light);
}

.info-text {
  flex-wrap: nowrap;
  overflow-wrap: normal;
  display: flex;
  justify-content: right;
  align-items: right;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Animations */

.header-underline {
  position: relative;
  color: var(--color-dark);
}

.header-underline-active.header-underline::after,
.header-underline:hover.header-underline::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header-underline::after {
  content: '';
  position: absolute;
  z-index: inherit;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background: var(--color-dark);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.scale-hover {
  transition: transform 0.3s ease-in;
}
.scale-hover:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-out;
}

/* Table hover navigation effect */
.nav-table .ant-table-tbody {
  cursor: pointer;
}
.nav-table .ant-table-tbody > tr:hover {
  filter: brightness(120%);
}
.nav-table .ant-table-tbody > tr:hover img {
  filter: brightness(90%) !important;
}

/* Animation Spin */

.anim-spin {
  animation: anim-spin 2s infinite linear;
}

@keyframes anim-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animation Shake */

@keyframes anim-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  8%,
  24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  12%,
  28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  40%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.anim-shake {
  animation: anim-shake 4s infinite linear;
}
